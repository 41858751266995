import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './main-layout.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { RegisterComponent } from './register/register.component';
const routes: Routes = [
  {
    path: 'main',
    component: MainLayoutComponent,
    children: [
      {
        path: 'header',
        pathMatch: 'full',
        component: HeaderComponent,
      },
      {
        path: 'dashboard',
        pathMatch: 'full',
        component: DashboardComponent,
      },
      {
        path: 'register',
        pathMatch: 'full',
        component: RegisterComponent,
      },
      {
        path: 'myProfile',
        loadChildren: () => import('./my-profile/my-profile.module').then(
          (module) => module.MyProfileModule)
      },
      {
        path: 'sidenav',
        pathMatch: 'full',
        component: SidenavComponent,
      },
      {
        path: 'usermessage',
        loadChildren: () => import(`./user-message/user-message.module`).then(
          module => module.UserMessageModule
        )
      },
      {
        path: 'country',
        loadChildren: () => import(`./master-setting/country/country.module`).then(
          module => module.CountryModule
        )
      },
      {
        path: 'state',
        loadChildren: () => import(`./master-setting/state/state.module`).then(
          module => module.StateModule
        ),
      },
      {
        path: 'region',
        loadChildren: () => import(`./master-setting/region/region.module`).then(
          module => module.RegionModule
        ),
      },
      {
        path: 'district',
        loadChildren: () => import(`./master-setting/district/district.module`).then(
          module => module.DistrictModule
        ),
      },
      {
        path: 'location',
        loadChildren: () => import(`./master-setting/location/location.module`).then(
          module => module.LocationModule
        ),
      },
      {
        path: 'deletedLocation',
        loadChildren: () => import(`./master-setting/deletedlocation/deletedlocation.module`).then(
          module => module.DeletedLocationModule
        ),
      },
      {
        path: 'pincode',
        loadChildren: () => import(`./master-setting/pincode/pincode.module`).then(
          module => module.PincodeModule
        ),
      },
      {
        path: 'postoffice',
        loadChildren: () => import(`./master-setting/postoffice/postoffice.module`).then(
          module => module.PostofficeModule
        ),
      },
      {
        path: 'location-bulk-import',
        loadChildren: () => import(`./master-setting/location-bulk-import/location-bulk-import.module`).then(
          module => module.LocationBulkImportModule
        ),
      },
      {
        path: 'taluka',
        loadChildren: () => import(`./master-setting/taluka/taluka.module`).then(
          module => module.TalukaModule
        ),
      },
      // {
      //   path: 'deedtype',
      //   loadChildren: () => import(`./master-setting/deedtype/deedtype.module`).then(
      //     module => module.DeedTypeModule
      //   ),
      // },
      {
        path: 'propertytype',
        loadChildren: () => import(`./master-setting/propertytype/property.module`).then(
          module => module.PropertyTypeModule
        ),
      },
      {
        path: 'buildingtype',
        loadChildren: () => import(`./master-setting/buildingtype/buildingtype.module`).then(
          module => module.BuildingTypeModule
        ),
      },
      {
        path: 'nearbyfacilities',
        loadChildren: () => import(`./master-setting/nearbyfacilities/nearbyfacilities.module`).then(
          module => module.NearByFacilitiesModule
        ),
      },
      {
        path: 'propertyfacing',
        loadChildren: () => import(`./master-setting/propertyfacing/propertyfacing.module`).then(
          module => module.PropertyFacingModule
        ),
      },
      {
        path: 'buildingfacing',
        loadChildren: () => import(`./master-setting/buildingfacing/buildingfacing.module`).then(
          module => module.BuildingFacingModule
        ),
      },
      {
        path: 'sellertype',
        loadChildren: () => import(`./master-setting/sellertype/sellertype.module`).then(
          module => module.sellertypeModule
        ),
      },
      {
        path: 'housetype',
        loadChildren: () => import(`./master-setting/housetype/housetype.module`).then(
          module => module.HouseypeModule
        ),
      },
      {
        path: 'leastduration',
        loadChildren: () => import(`./master-setting/leastduration/leastduration.module`).then(
          module => module.LeastDurationModule
        ),
      },
      {
        path: 'housemodel',
        loadChildren: () => import(`./master-setting/housemodel/housemodel.module`).then(
          module => module.HousemodelModule
        ),
      },
      {
        path: 'bhk',
        loadChildren: () => import(`./master-setting/bhk/bhk.module`).then(
          module => module.BhkModule
        ),
      },
      {
        path: 'amenities',
        loadChildren: () => import(`./master-setting/amenities/amenities.module`).then(
          module => module.AmenitiesModule
        ),
      },
      {
        path: 'frontdoortype',
        loadChildren: () => import(`./master-setting/frontdoortype/frontdoortype.module`).then(
          module => module.FrontDoorTypeModule
        ),
      },
      {
        path: 'dimensioninches',
        loadChildren: () => import(`./master-setting/dimensioninches/dimensioninches.module`).then(
          module => module.DimensionInchesModule
        ),
      },
      {
        path: 'plotfacing',
        loadChildren: () => import(`./master-setting/plotfacing/plotfacing.module`).then(
          module => module.PlotFacingModule
        ),
      },
      {
        path: 'doorfacing',
        loadChildren: () => import(`./master-setting/doorfacing/doorfacing.module`).then(
          module => module.DoorFacingModule
        ),
      },
      {
        path: 'linktype',
        loadChildren: () => import(`./master-setting/linktype/linktype.module`).then(
          module => module.LinkTypeModule
        ),
      },
      {
        path: 'planlist',
        loadChildren: () => import(`./master-setting/planlist/planlist.module`).then(
          module => module.PlanListModule
        ),
      },
      {
        path: 'kitchentype',
        loadChildren: () => import(`./master-setting/kitchentype/kitchentype.module`).then(
          module => module.KitchenTypeModule
        ),
      },
      {
        path: 'flooringtype',
        loadChildren: () => import(`./master-setting/flooringtype/flooringtype.module`).then(
          module => module.FlooringTypeModule
        ),
      },
      {
        path: 'waterfacility',
        loadChildren: () => import(`./master-setting/waterfacility/waterfacility.module`).then(
          module => module.WaterFacilityModule
        ),
      },
      {
        path: 'electricity',
        loadChildren: () => import(`./master-setting/electricity/electricity.module`).then(
          module => module.ElectricityModule
        ),
      },
      {
        path: 'currentyield',
        loadChildren: () => import(`./master-setting/currentyield/currentyield.module`).then(
          module => module.CurrentyieldModule
        ),
      },
      {
        path: 'soiltype',
        loadChildren: () => import(`./master-setting/soiltype/soiltype.module`).then(
          module => module.SoiltypeModule
        ),
      },
      {
        path: 'landSuitableFor',
        loadChildren: () => import('./master-setting/land-suitable-for/land-suitable-for.module').then(
          module => module.LandSuitableForModule
        )
      },
      {
        path: 'boundary',
        loadChildren: () => import('./master-setting/boundary/boundary.module').then(
          module => module.BoundaryModule
        )
      },
      {
        path: 'approvedby',
        loadChildren: () => import('./master-setting/approvedby/approvedby.module').then(
          module => module.ApprovedByModule
        )
      },
      {
        path: 'yearlyincome',
        loadChildren: () => import('./master-setting/yearlyincome/yearlyincome.module').then(
          module => module.YearlyincomeModule
        )
      },
      {
        path: 'beds',
        loadChildren: () => import('./master-setting/beds/beds.module').then(
          module => module.BedsModule
        )
      },
      {
        path: 'bath',
        loadChildren: () => import('./master-setting/bath/bath.module').then(
          module => module.BathModule
        )
      },
      {
        path: 'customerPlan',
        loadChildren: () => import('./master-setting/customerplan/customerplan.module').then(
          module => module.CustomerPlanModule
        )
      },
      {
        path: 'customerPlanRequest',
        loadChildren: () => import('./master-setting/customerplanrequest/customerplanrequest.module').then(
          module => module.CustomerPlanRequestModule
        )
      },
      {
        path: 'termsAndCondition',
        loadChildren: () => import('./master-setting/termsandcondition/termsandcondition/termsandcondition.module').then(
          module => module.TermsAndConditionModule
        )
      },
      {
        path: 'kitchen',
        loadChildren: () => import('./master-setting/kitchen/kitchen.module').then(
          module => module.KitchenModule
        )
      },
      {
        path: 'livingRoom',
        loadChildren: () => import('./master-setting/livingRoom/livingRoom.module').then(
          module => module.LivingRoomModule
        )
      },
   
      {
        path: 'balcony',
        loadChildren: () => import('./master-setting/balcony/balcony.module').then(
          module => module.BalconyModule
        )
      },
      {
        path: 'totalRoom',
        loadChildren: () => import('./master-setting/totalRoom/totalRoom.module').then(
          module => module.TotalRoomModule
        )
      },
      {
        path: 'roadAccess',
        loadChildren: () => import('./master-setting/roadAccess/roadAccess.module').then(
          module => module.RoadAccessModule
        )
      },
      {
        path: 'parking',
        loadChildren: () => import('./master-setting/parking/parking.module').then(
          module => module.ParkingModule
        )
      },
      {
        path: 'areaunittype',
        loadChildren: () => import('./master-setting/area-unit-type/areaunittype.module').then(
          module => module.AreaUnitTypeModule
        )
      },
      {
        path: 'price',
        loadChildren: () => import('./master-setting/price/price.module').then(
          module => module.PriceModule
        )
      },
      {
        path: 'footer',
        loadChildren: () => import('./master-setting/footer/footer.module').then(
          module => module.FooterModule
        )
      },
      {
        path: 'aboutUs',
        loadChildren: () => import('./master-setting/about-us/about-us.module').then(
          module => module.AboutUsModule
        )
      },
      {
        path: 'profession',
        loadChildren: () => import('./master-setting/profession/profession.module').then(
          module => module.ProfessionModule
        )
      },
      {
        path: 'popularTown',
        loadChildren: () => import('./master-setting/populartown/populartown.module').then(
          module => module.PopulartownModule
        )
      },
      {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      },
      {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      }, {
        path: 'professionPageService',
        loadChildren: () => import('./master-setting/profession-page-service/profession-page-service.module').then(
          module => module.ProfessionPageServiceModule
        )
      },
       {
        path: 'callToAction',
        loadChildren: () => import('./master-setting/call-to-action/call-to-action.module').then(
          module => module.CallToActionModule
        )
      },
      {
        path: 'tag',
        loadChildren: () => import('./master-setting/tag/tag.module').then(
          module => module.TagModule
        )
      },
      // {
      //   path: 'paidTag',
      //   loadChildren: () => import('./master-setting/tag/tag.module').then(
      //     module => module.TagModule
      //   )
      // },
      {
        path: 'report',
        loadChildren: () => import('./master-setting/report/report.module').then(
          module => module.ReportModule
        )
      },
      {
        path: 'professionalPageReport',
        loadChildren: () => import('./master-setting/professional-page-report/professional-page-report.module').then(
          module => module.ProfessionalPageReportModule
        )
      },
      {
        path: 'professionalPostReport',
        loadChildren: () => import('./master-setting/professional-post-report/professional-post-report.module').then(
          module => module.ProfessionalPostReportModule
        )
      },
      {
        path: 'professionalLimit',
        loadChildren: () => import('./master-setting/professional-limit/professional-limit.module').then(
          module => module.ProfessionalLimitModule
        )
      },
      {
        path: 'deletedPost',
        loadChildren: () => import('./master-setting/deleted-post/deleted-post.module').then(
          module => module.DeletedPostModule
        )
      },
      {
        path: 'professionPageList',
        loadChildren: () => import('./master-setting/profession-page-list/profession-page-list.module').then(
          module => module.ProfessionPageListModule
        )
      },
      {
        path: 'label',
        loadChildren: () => import('./master-setting/label/label.module').then(
          module => module.LabelModule
        )
      },
      {
        path: 'locationPriority',
        loadChildren: () => import('./master-setting/locationPriority/locationPriority.module').then(
          module => module.LocationPriorityModule
        )
      },
      {
        path: 'loan',
        loadChildren: () => import('./master-setting/loan/loan-module').then(
          module => module.LoanModule
        )
      },
      {
        path: 'benefit',
        loadChildren: () => import('./master-setting/benefit/benefit.module').then(
          module => module.BenefitModule
        )
      },
      {
        path: 'propertyLoanView',
        loadChildren: () => import(`./../property-loan-view/property-loan-view.module`).then(
          (module) => module.PropertyLoanViewModule)
      },
      {
        path: 'contactList',
        loadChildren: () => import(`./../contact-list/contact-list-module`).then(
          (module) => module.ContactListModule)
      },
      {
        path: 'propertyList',
        loadChildren: () => import(`./../property-list/property-list.module`).then(
          (module) => module.PropertyListModule)
      },
      {
        path: 'contactDetails',
        loadChildren: () => import('./../contact-details/contact-details.module').then(
          (module) => module.ContactDetailsModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./../user/user.module').then(
          (module) => module.UserModule)
      },
      {
        path: 'assignContacts',
        loadChildren: () => import('./../assign-contacts/assign-contact.module').then(
          (module) => module.AssignContactModule)
      },
      {
        path: 'assignProperty',
        loadChildren: () => import('./../assign-property/assign-property.module').then(
          (module) => module.AssignPropertyModule)
      },
      {
        path: 'myPromoCodes',
        loadChildren: () => import('./../my-promocodes/my-promocodes.module').then(
          (module) => module.MyPromocodesModule)
      },
      {
        path: 'myassignedProperty',
        loadChildren: () => import('./../my-assignedproperty/my-assignedproperty.module').then(
          (module) => module.MyAssignedpropertyModule)
      },
      {
        path: 'task',
        loadChildren: () => import('../main-layout/assign-task/assign-task.module').then(
          (module) => module.AssignTaskModule)
      },
      {
        path: 'messageTask',
        loadChildren: () => import('../main-layout/assigned-message-task/assigned-message-task.module').then(
          (module) => module.AssignedMessageTaskModule)
      },
      {
        path: 'viewregisterted',
        loadChildren: () => import('../view-registerted/view-registerted.module').then(
          (module) => module.ViewRegistertedModule)
      },
      {
        path: 'promocoderegisterted',
        loadChildren: () => import('../main-layout/master-setting/promocode_registerted/promocode-registerted/promocode-registerted.module').then(
          (module) => module.PromocodeRegistertedModule)
      },
      {
        path: 'contactEntryType',
        loadChildren: () => import('./master-setting/contact-entry-type/contact-entry-type.module').then(
          (module) => module.ContactEntryTypeModuleModule)
      },
      {
        path: 'membeship-plan',
        loadChildren: () => import('./membership-plan/membership-plan.module').then(
          (module) => module.MembershipPlanModule)
      }
    ]
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainLayoutRoutingModule { }
